import { ITeaserProps, IOptionButton } from "./Teaser.types";
import { ICONS } from "./icons";

const renderOptionHtml = (option: IOptionButton, props: ITeaserProps) => {
  const classList = ["option-button"];

  if (option.primary) {
    classList.push("primary");
  }
  let icon = option.icon && ICONS[option.icon] ? ICONS[option.icon] : "";
  if (icon) {
    classList.push("with-icon");
  }
  if (option.subLabel) {
    classList.push("with-sub-label");
  }

  const isScheduleTourSubLabel = option.subLabel?.includes("other recent inquiries");
  const showSubLabel = isScheduleTourSubLabel ? (option.subLabel && props.showRecentTourInquiries) : !!option.subLabel;

  return /*HTML*/ `
  <label class="${classList.join(" ")}">
    <input type="radio" name="segment_1" value="${option.action}" />
    ${icon ? '<span class="icon sentinel"></span>' : ""}
    <span class="label-wrapper">
      <span>${option.label}</span>
      ${showSubLabel ? `<span>${option.subLabel}</span>` : ""}
    </span>
    ${icon}
  </label>
`;
};

export const renderThemeVars = (props: ITeaserProps) => {
  const t = {
    fontFamily: "helvetica neue",
    fontSize: "16px",
    color: "#121212",
    fontWeight: "normal",
    lineHeight: "normal",
    brandColor: "#14386e",
    teaserBackground: "#ffffff",
    teaserBorder: "none",
    teaserBorderRadius: "10px",
    teaserMinWidth: "300px",
    teaserMinHeight: "60px",
    teaserMaxWidth: "1800px",
    teaserMaxHeight: "100%",
    teaserBoxShadow: "0 15px 40px 0 rgba(51, 51, 51, 0.15)",
    teaserPadding: "16px 30px",
    teaserMargin: "0 auto",
    teaserSegmentsGap: "16px",
    buttonHeight: "60px",
    buttonColor: "#ffffff",
    buttonShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.12)",
    buttonLabelFontSize: "initial",
    buttonSublabelFontSize: "initial",
    buttonsBorder: "none",
    buttonsBorderRadius: "6px",
    justifyTeaserButtons: "flex-end",
    headerTitleDisplay: "block",
    headerTitleFontFamily: "initial",
    headerTitleFontSize: "26px",
    headerTitleColor: "initial",
    headerTitleFontWeight: "initial",
    headerTitleLineHeight: "normal",
    headerSubtitleDisplay: "block",
    headerSubtitleFontFamily: "initial",
    headerSubtitleFontSize: "initial",
    headerSubtitleColor: "initial",
    headerSubtitleFontWeight: "initial",
    headerSubtitleLineHeight: "initial",
    ...props.theme,
  };

  return Object.entries(t)
    .map(([key, value]) => `--${key}: ${value};`)
    .join("");
};

export const renderTeaserTemplate = (props: ITeaserProps) => /*HTML*/ `
<div
  class="Teaser"
  style="${renderThemeVars(props)}"
>
  <form class="segments">
    <div class="Header">
      <h1>${props.title}</h1>
      <h2>${props.subtitle}</h2>
    </div>
    <div class="Options">
    ${props.options.map((option) => renderOptionHtml(option, props)).join("")}
    </div>
  </form>
</div>
`;
