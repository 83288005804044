import { styles } from "./Teaser.styles";
import { IOptionButton, ITeaserProps, TeaserHtmlProps } from "./Teaser.types";
import { renderTeaserTemplate } from "./Teaser.template";

declare global {
  interface Window {
    FurtherSiteManager: any;
  }
}

export function renderServerSide(props: ITeaserProps) {
  return `
  <template shadowrootmode="open">
      <style>
        ${styles}
      </style>

      <style>
        ${props.customCss}
      </style>

      ${renderTeaserTemplate(props)}
  </template>
  `;
}

export function renderClientSide(props: ITeaserProps) {
  return `
      <style>
        ${styles}
      </style>

      <style>
        ${props.customCss}
      </style>

      ${renderTeaserTemplate(props)}
  `;
}

export function registerTeaserWebComponent() {
  if (typeof window !== "undefined" && typeof HTMLElement !== "undefined") {
    class TeaserElement extends HTMLElement {
      state: ITeaserProps;
      resizeObserver: ResizeObserver;

      constructor() {
        super();

        this.state = {
          vsaInstance: "",
          title: "",
          subtitle: "",
          options: [],
          showRecentTourInquiries: true,
          theme: {},
          customCss: "",
        };

        this.render();
      }

      static register() {
        if (!window.customElements.get("further-teaser")) {
          window.customElements.define("further-teaser", TeaserElement);
        }
      }

      get vsaInstance() {
        return this.state?.vsaInstance || "";
      }

      set vsaInstance(vsaInstance: string) {
        this.state!.vsaInstance = vsaInstance;
        this.render();
      }

      get title() {
        return this.state?.title || "";
      }

      set title(title: string) {
        this.state!.title = title;
        this.render();
      }

      get subtitle() {
        return this.state?.subtitle || "";
      }

      set subtitle(subtitle: string) {
        this.state!.subtitle = subtitle;
        this.render();
      }

      get options() {
        return this.state?.options || [];
      }

      set options(options: IOptionButton[]) {
        this.state!.options = options;
        this.render();
      }

      get showRecentTourInquiries() {
        return this.state?.showRecentTourInquiries || false;
      }

      set showRecentTourInquiries(showRecentTourInquiries: boolean) {
        this.state!.showRecentTourInquiries = showRecentTourInquiries;
        if (this.vsaInstance && showRecentTourInquiries) {
          this.populateInterestedCount();
        } else {
          this.render();
        }
      }

      get theme() {
        return this.state?.theme || {};
      }

      set theme(theme: ITeaserProps["theme"]) {
        this.state!.theme = theme;
        this.render();
      }

      get customCss() {
        return this.state?.customCss || "";
      }

      set customCss(customCss: string) {
        this.state!.customCss = customCss;
        this.render();
      }

      connectedCallback() {
        this.render();
        this.populateInterestedCount();
      }

      disconnectedCallback() {
        this.resizeObserver?.disconnect();
      }

      static get observedAttributes() {
        return ["vsa-instance", "title", "subtitle", "options", "show-recent-tour-inquiries", "theme", "custom-css"];
      }

      // This callback is invoked at the initial load of the component
      attributeChangedCallback(name: TeaserHtmlProps, _: any, newValue: any) {
        if (!this.state) return;

        const key = {
          "vsa-instance": "vsaInstance",
          title: "title",
          subtitle: "subtitle",
          options: "options",
          "show-recent-tour-inquiries": "showRecentTourInquiries",
          theme: "theme",
          "custom-css": "customCss",
        }[name];

        try {
          this.state[key] = JSON.parse(newValue);
        } catch (e) {
          this.state[key] = newValue;
        }
        this.render();
      }

      render() {
        if (!this.shadowRoot) {
          this.innerHTML = "";
          this.attachShadow({ mode: "open" });
        }

        if (this.resizeObserver) {
          this.resizeObserver.disconnect();
        }

        const r = this.shadowRoot!;

        r.innerHTML = renderClientSide(this.state);

        const form = r.querySelector("form");
        if (!form) return;

        form.addEventListener("submit", async (e) => {
          e.preventDefault();
          const formData = new FormData(form);
          const targetModule = formData.get("segment_1") as string;
          if (targetModule) {
            const optionButtons = form.querySelectorAll(".option-button");
            optionButtons.forEach((button) => {
              button.classList.add("disabled");
            });
            await this.openEmbeddedVSA(targetModule);
            optionButtons.forEach((button) => {
              button.classList.remove("disabled", "loading");
            });
          }
        });

        const options = r.querySelector(".Options");
        if (!options) return;

        r.querySelectorAll(".option-button").forEach((option) => {
          option.addEventListener("change", (e) => {
            const clickedButton = e.currentTarget as HTMLLabelElement;
            clickedButton.classList.add("loading");
            form.dispatchEvent(
              new Event("submit", {
                bubbles: true,
                cancelable: true,
              })
            );
            (e.target as HTMLInputElement).checked = false;
          });
        });

        this.initResizeObserver();
      }

      async initResizeObserver() {
        if (!window.ResizeObserver) {
          const { install } = await import("resize-observer");
          install();
        }

        const teaserRoot = this.shadowRoot?.querySelector(".Teaser");

        this.resizeObserver = new ResizeObserver((entries) => {
          entries.forEach((entry) => {
            if (entry?.target === this.shadowRoot?.host) {
              const width = entry.contentRect.width;
              const isHorizontal = width > 670;
              if (isHorizontal) {
                teaserRoot?.classList.remove("vertical");
                teaserRoot?.classList.add("horizontal");
              } else {
                teaserRoot?.classList.remove("horizontal");
                teaserRoot?.classList.add("vertical");
              }

              const wrapSegments = isHorizontal && teaserRoot?.clientWidth! < 1000;
              if (wrapSegments) {
                teaserRoot?.classList.add("wrap-segments");
              } else {
                teaserRoot?.classList.remove("wrap-segments");
              }
            }
          });
        });

        this.resizeObserver.observe(this.shadowRoot!.host);
      }

      async loadSiteManager() {
        // Would be nice to do const SiteManager = await import("https://js.talkfurther.com/talkfurther_init.min.js");
        // but that's not supported yet on the Site Manager side of things.
        return new Promise((resolve, reject) => {
          var script = document.createElement("script");
          script.type = "text/javascript";
          script.src = "https://js.talkfurther.com/talkfurther_init.min.js";
          script.async = true;
          document.body.appendChild(script);
          let attempts = 0;
          const interval = setInterval(() => {
            if (window.FurtherSiteManager) {
              clearInterval(interval);
              resolve(true);
            } else {
              attempts++;
              if (attempts > 1000) {
                clearInterval(interval);
                reject("Site Manager failed to load");
              }
            }
          }, 10);
        });
      }

      async populateInterestedCount() {
        if (this.state.showRecentTourInquiries === false) {
          return;
        }

        const SCHEDULE_TOUR_KEYWORDS = [" visit", " tour", " appointment", " call", " experience"];
        const hasScheduleTourOption = this.state.options.some((option) =>
          SCHEDULE_TOUR_KEYWORDS.some((keyword) => option.label.toLocaleLowerCase().includes(keyword))
        );
        const hasTourButton = this.state.options.some((option) => option.isTourButton);

        if (!hasScheduleTourOption && !hasTourButton) {
          return;
        }

        try {
          if (!window.FurtherSiteManager) {
            console.log("Site Manager not loaded, loading...");
            await this.loadSiteManager();
          }

          const interestedCount = await window.FurtherSiteManager.getInterestedCount(this.vsaInstance);

          if (!interestedCount) {
            return;
          }

          this.state.options = this.state.options.map((option) => {
            if (option.isTourButton === false) {
              return option;
            }

            const isScheduleTourLabel = SCHEDULE_TOUR_KEYWORDS.some((keyword) =>
              option.label.toLocaleLowerCase().includes(keyword)
            );

            if (isScheduleTourLabel || option.isTourButton) {
              option.subLabel = `Join ${interestedCount} other recent inquiries`;
            }
            return option;
          });
          this.render();
        } catch (e) {
          console.error(e);
        }
      }

      async openEmbeddedVSA(targetModule: string) {
        try {
          if (!window.FurtherSiteManager) {
            console.log("Site Manager not loaded, loading...");
            await this.loadSiteManager();
          }

          await window.FurtherSiteManager.openEmbeddedVSA({
            vsaInstance: this.vsaInstance,
            targetModule,
          });
        } catch (e) {
          console.error(e);
        }
      }
    }

    TeaserElement.register();
  }
}
